import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { map, path, pipe, sortBy, prop, pathOr } from 'ramda'

import LayoutFullWidth from '../../components/Layout/LayoutFullWidth'
import headerProps from '../../components/propTypes/headerProps'
import {
  Divider,
  Breadcrumbs,
  Typography,
  PinBlockGroup,
  AggregatorPin4,
  TransitionLink,
} from '../../components'
import styles from './TeamPageTemplate.module.css'

const flattenProfiles = pipe(
  map(path(['node'])),
  map(p => ({
    ...path(['frontmatter'], p),
    ...path(['fields'], p),
  })),
)

const TeamPage = ({ data }) => {
  const {
    page: {
      frontmatter: { header },
    },
    categories
  } = data
  const profiles = flattenProfiles(data.profiles.edges)
  const cats = map(path(['node', 'frontmatter', 'title']), categories.edges)
  const order = ['Core Team', 'Co-Course Convenors', 'Consultants']
  const remainingCats = cats.filter(c => !order.includes(c))
  const orderedCats = [...order, ...remainingCats]
  const oderedCatsNoScholars  = orderedCats.filter(oc => !oc.toLowerCase().includes("scholar"))

  return (
    <LayoutFullWidth header={header}>
      <div className="page-wrapper container">
        <Breadcrumbs>
          <Breadcrumbs.Item>
            <TransitionLink to="/about-us">About Us</TransitionLink>
          </Breadcrumbs.Item>
          <Breadcrumbs.Item>
            <span>Our Team</span>
          </Breadcrumbs.Item>
        </Breadcrumbs>

        <Divider thickness={4} />
        <Typography tag="h2" type="boxHeading">
          Bertha Centre Team
        </Typography>

        {oderedCatsNoScholars.map((cat, i) => {
          const catProfiles = profiles.filter(p => pathOr([], ['teamCategory'], p).includes(cat))
          return catProfiles.length > 0 && (
            <>
              <PinBlockGroup key={i} sectionTitle={cat} columns={2}>
                {
                  pipe(
                    sortBy(prop('title')),
                    map((profile, i) => (
                      <AggregatorPin4
                        key={i}
                        title={profile.title}
                        thumbnailSrc={profile.featuredImage}
                        href={profile.slug}
                        className={styles.teamPin}
                      >
                        {profile.additionalInfo && (
                          <strong>{profile.additionalInfo}</strong>
                        )}
                        <p>{profile.excerpt}...</p>
                      </AggregatorPin4>
                    ))
                  )(catProfiles)
                }
              </PinBlockGroup>
            </>
          )
        })}
      </div>
    </LayoutFullWidth>
  )
}

TeamPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      frontmatter: PropTypes.shape({
        header: headerProps,
      }),
    }),
    categories: PropTypes.arrayOf(PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
      }),
    })),
    profiles: PropTypes.arrayOf(PropTypes.any),
  }),
}

export default TeamPage

export const pageQuery = graphql`
  query TeamPageTemplate {
    page: mdx(frontmatter: { title: { eq: "About Us" } }) {
      frontmatter {
        ...headerFields
      }
    }
    categories: allMdx(filter: {fields: {contentType: {eq: "teamCategories"}}}, limit: 1000) {
      edges {
        node {
          frontmatter {
            title
          }
        }
      }
    }
    profiles: allMdx(
      filter: { fields: { contentType: { eq: "teamProfiles" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            additionalInfo
            teamCategory
            excerpt
            editor
            featuredImage {
              childCloudinaryMediaImage {
                fixed(
                  width: 150
                  transformations: "w_150,h_150,c_thumb,g_face"
                ) {
                  height
                  src
                  srcSet
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`
